import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e150d7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "ribbon-content" }
const _hoisted_3 = { class: "grid md:grid-cols-3 lg:grid-cols-6 divide-y divide-gray-200 md:divide-x md:divide-y-0 mb-8" }
const _hoisted_4 = { class: "h-18" }
const _hoisted_5 = { class: "col-span-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sc_text = _resolveComponent("sc-text")!
  const _component_sc_rich_text = _resolveComponent("sc-rich-text")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_link_button = _resolveComponent("link-button")!

  return (_openBlock(), _createElementBlock("section", {
    class: "ribbon",
    id: _ctx.fields.anchorName.value
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_sc_text, {
        tag: "h2",
        field: _ctx.fields.title
      }, null, 8, ["field"]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields.items, (item) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            key: item.fields.id,
            to: item.url,
            class: "grid grid-cols-3 md:grid-cols-1 md:mb-4 py-4 md:px-8 first:md:-ml-8 text-gray-500 content-start group"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_sc_rich_text, {
                  tag: "span",
                  field: item.fields.productType.fields.Value,
                  class: "grid icon text-7xl text-primary transition-all duration-500 group-hover:-translate-y-1"
                }, null, 8, ["field"])
              ]),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_sc_text, {
                  tag: "h3",
                  field: item.fields.pageTitle,
                  class: "md:mt-4 mb-0"
                }, null, 8, ["field"]),
                _createVNode(_component_sc_text, {
                  tag: "p",
                  field: item.fields.content,
                  class: "mt-0 mb-0"
                }, null, 8, ["field"])
              ])
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ]),
      (_ctx.fields.link.title)
        ? (_openBlock(), _createBlock(_component_link_button, {
            key: 0,
            link: _ctx.fields.link
          }, null, 8, ["link"]))
        : _createCommentVNode("", true)
    ])
  ], 8, _hoisted_1))
}