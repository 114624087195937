
import { defineComponent } from 'vue';
import IBSuiteService from '../services/ibsuite.service';
import { useCookies } from 'vue3-cookies';

var cprValidate = require('danish-ssn');

export default defineComponent({
    name: 'Onboarding',
    components: {},
    data() {
        const { cookies } = useCookies();

        if (!cookies.isKey('lberhverv.session')) {
            this.$router.push('/miterhverv');
        }

        let cookiePayload = new URLSearchParams(decodeURI(cookies.get('lberhverv.session')));

        let cvr = cookiePayload.get('cvr');
        let cpr = cookiePayload.get('cpr');
        let hasUuid = cookiePayload.get('hasUuid');

        const currentStep = cvr || cpr ? 'CVR' : hasUuid ? 'CPR' : 'CVR';

        const validateCprWrapper = (strCpr) => {
            strCpr = strCpr.substr(0, 6) + '-' + strCpr.substr(6, 4);
            return cprValidate(strCpr).valid;
        };
        return {
            currentStep: currentStep,
            cvr: cvr,
            cpr: cpr,
            existingUser: false,
            CREATED: false,
            registrationDeleted: false,
            deleteReg: false,
            validateCprWrapper,
            cprValid: false,
            noCvrMatch: false,
        };
    },
    computed: {
        isCvrValid: function (): boolean {
            return this.cvr != null && this.cvr.length > 7;
        },
    },
    methods: {
        async save() {
            let cvr = this.cvr as string;
            let cpr = this.cpr || '';
            let memberFound = false;
            if (this.isPrivate(this.cpr, this.cvr)) {
                memberFound = await IBSuiteService.cvrMapperUuid(cpr, cvr);
            } else {
                memberFound = await IBSuiteService.cvrMapperRid(cvr);
            }

            if (memberFound == false) {
                this.noCvrMatch = true;
                return;
            }

            let createdMember = await IBSuiteService.createMember(this.cpr, cvr).then((res) => {
                if (res) {
                    this.existingUser = false;
                    this.CREATED = true;
                    this.registrationDeleted = false;
                    this.deleteReg = false;
                    return true;
                }
                return false;
            });

            if (createdMember == false) {
                return;
            }

            await IBSuiteService.exchangeOnboardingToken().then(() => {
                this.$store.dispatch('login', {
                    isLoggedIn: true,
                });
                this.currentStep = 'CREATED';
            });
        },
        async deleteRegistration() {
            return IBSuiteService.deleteMember().then(() => {
                this.registrationDeleted = true;
                this.deleteReg = false;
                this.existingUser = false;
                this.CREATED = false;
            });
        },
        isNumber: function (evt: KeyboardEvent) {
            this.registrationDeleted = false;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        validateCpr: function (cpr: string | null) {
            if (cpr === null) {
                this.cprValid = false;
                return this.cprValid;
            }

            try {
                this.registrationDeleted = false;
                this.cprValid = cpr.length === 10 && cprValidate(cpr);
            } catch (ex) {
                console.error(ex);
                this.cprValid = false;
            }
            return this.cprValid;
        },
        enterCvrStep: function () {
            this.currentStep = 'CVR';
        },
        resetCpr: function (evt: KeyboardEvent) {
            if (this.cpr) {
                var charCode = evt.which ? evt.which : evt.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                    evt.preventDefault();
                } else {
                    this.cvr = '';
                }
            }
        },
        gotoRestricted() {
            this.$router.push('/restricted');
        },
        isPrivate(cpr: string, cvr: string) {
            return cpr && cpr !== '' && cvr && cvr !== '';
        },
    },
});
