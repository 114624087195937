
import { Link } from '@sitecore-jss/sitecore-jss-vue';
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        ScLink: Link,
    },
    props: {
        link: {
            type: Object,
            default: () => ({}),
        },
    },
    setup() {
        return;
    },
});
