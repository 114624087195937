
import { defineComponent } from 'vue';
import Overlay from './Overlay.vue';

export default defineComponent({
    name: 'Loader',
    components: { Overlay },
    computed: {
        show(): boolean {
            return this.$store.state.loading;
        },
    },
    mounted() {
        this.show ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll');
    },
    watch: {
        show(val) {
            val ? document.body.classList.add('no-scroll') : document.body.classList.remove('no-scroll');
        },
    },
});
