
import { Link } from '@sitecore-jss/sitecore-jss-vue';
import { defineComponent, PropType } from 'vue';

type ButtonSize = 'default' | 'fixed' | 'small' | 'minimal';
type ButtonType = 'default' | 'outline' | 'text';

export default defineComponent({
    name: 'LinkButton',
    components: {
        ScLink: Link,
    },
    props: {
        link: {
            type: Object,
            default: () => ({}),
        },
        size: {
            type: String as PropType<ButtonSize>,
            default: () => 'default' as ButtonSize,
        },
        type: {
            type: String as PropType<ButtonType>,
            default: () => 'default' as ButtonType,
        },
        icon: {
            type: Boolean,
            default() {
                return true;
            },
        },
        secondary: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    computed: {
        isFixed(): Boolean {
            return this.size === 'fixed';
        },
        isMinimal(): boolean {
            return this.size === 'minimal';
        },
        isOutlined(): Boolean {
            return this.type === 'outline';
        },
        isSmall(): Boolean {
            return this.size === 'small';
        },
        isText(): Boolean {
            return this.type === 'text';
        },
    },
});
