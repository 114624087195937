<template>
    <div class="py-4">
        <sc-text
            :field="item.fields.title"
            tag="h3"
            class="cursor-pointer relative mb-0 after:font-icon after:content-['n'] after:absolute after:right-0 after:transition-all"
            :class="{ 'after:rotate-45': rotate }"
            @click="toggle($event)"
        />
        <span class="hidden">
            <sc-rich-text :field="item.fields.content" />
        </span>
    </div>
</template>

<script>
import { RichText, Text } from '@sitecore-jss/sitecore-jss-vue';
import { defineComponent, ref } from 'vue';
import $ from 'jquery';

export default defineComponent({
    name: 'AccordionItem',
    components: {
        ScRichText: RichText,
        ScText: Text,
    },
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
    },
    setup() {
        const rotate = ref(false);

        return {
            rotate,
        };
    },

    methods: {
        toggle(e) {
            $(e.target).next().slideToggle();
            this.rotate = !this.rotate;
        },
    },
});
</script>
