
import { Link } from '@sitecore-jss/sitecore-jss-vue';
import { defineComponent, PropType } from 'vue';
import { ArrowLink } from './ArrowLink.types';

export default defineComponent({
    components: {
        ScLink: Link,
    },
    props: {
        link: {
            type: Object as PropType<ArrowLink>,
            default() {
                return {
                    value: {
                        href: '',
                        text: '',
                        anchor: '',
                        linktype: '',
                        class: '',
                        title: '',
                        querystring: '',
                        id: '',
                    },
                };
            },
        },
    },
});
