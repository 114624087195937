
import { defineComponent } from 'vue';

export default defineComponent({
    naem: 'Loader',
    props: {
        show: {
            type: Boolean,
            default: () => false,
        },
    },
    setup() {},
});
