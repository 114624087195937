import { SitecoreJssPlaceholderPlugin } from '@sitecore-jss/sitecore-jss-vue';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { createApp as createVueApp, createSSRApp, h } from 'vue';
import { createMetaManager as createVueMetaManager, deepestResolver, defaultConfig } from 'vue-meta';
import { createStore } from 'vuex';
import AppRoot from './AppRoot';
import vScroll from './directives/vScroll';
import GraphQLClientFactory from './lib/GraphQLClientFactory';
import SitecoreJssStorePlugin from './lib/SitecoreJssStorePlugin';
import { createRouter } from './router';
import componentFactory from './temp/componentFactory';
import config from './temp/config';
import { httpExceptionInterceptor } from './interceptors/httpExceptionInterceptor';
import IBSuiteService from './services/ibsuite.service';

const createMetaManager = (isSSR = false) =>
    createVueMetaManager(
        isSSR,
        {
            ...defaultConfig,
        },
        deepestResolver
    );

// createApp is invoked by both the main and SSR entry points, so the two entry points can use the same app creation process.
export function createApp(initialState, i18n, isSSR) {
    const router = createRouter(isSSR);
    const metaManager = createMetaManager(isSSR);
    const graphQLProvider = createGraphQLProvider(initialState);

    const vueOptions = {
        router,
        render: () => h(AppRoot),
        i18n,
    };

    const app = isSSR ? createSSRApp(vueOptions) : createVueApp(vueOptions);

    app.provide(DefaultApolloClient, graphQLProvider);

    app.use(router);
    app.use(SitecoreJssStorePlugin);
    app.use(SitecoreJssPlaceholderPlugin, { componentFactory });
    app.use(i18n);
    app.use(metaManager);

    // if there is an initial state defined, push it into the store, where it can be referenced by interested components.
    if (initialState) {
        app.config.globalProperties.$jss.store.setSitecoreData(initialState);
    }

    app.directive('focus', {
        mounted(el) {
            el.focus();
        },
        updated(el) {
            // Might not be needed - Handle dynamic Vue component insert?!?
            el.focus();
        },
    });

    app.directive('scroll', vScroll);

    /* TODO: Move to file */
    const warning = createStore({
        state: {
            has: false,
            message: '',
            action: '',
        },
        mutations: {
            log(state, payload) {
                state.has = payload.has;
                state.message = payload.message;
                state.action = payload.action;
            },
        },
    });
    app.use(warning);
    app.provide('$warning', warning);
    app.config.globalProperties.$warning = warning;

    const store = createStore({
        state: {
            loggedIn: false,
            name: '',
            route: '',
            quoteAccepted: false,
            toastMessage: {
                label: '',
                type: null,
            },
        },
        mutations: {
            login(state, payload) {
                state.loggedIn = payload.loggedIn;
                if (payload.name) {
                    state.name = payload.name;
                }
            },
            logout(state, payload) {
                state.loggedIn = false;
                state.name = null;
                IBSuiteService.logout();

                if (payload) {
                    window.location.assign(payload.target);
                }
            },
            setRoute(state, payload) {
                state.route = payload;
            },
            setQuoteAccepted(state, payload) {
                state.quoteAccepted = payload;
            },
            setToastMessage(state, payload) {
                state.toastMessage = payload;
            },
            setLoading(state, payload) {
                state.loading = payload;
            },
        },
        actions: {
            login(state, payload) {
                store.commit('login', {
                    loggedIn: payload.isLoggedIn,
                    name: payload.name,
                });
            },
            logout(state, payload) {
                store.commit('logout', payload);
            },
            mapRoute({ commit }, payload) {
                commit('setRoute', payload);
            },
            quoteAccepted({ commit }, payload) {
                commit('setQuoteAccepted', payload);
            },
            toastMessage({ commit }, payload) {
                commit('setToastMessage', payload);
            },
            loading({ commit }, payload) {
                commit('setLoading', payload);
            },
        },
        getters: {},
    });
    app.use(store);
    app.provide('$store', store);
    app.config.globalProperties.$store = store;

    httpExceptionInterceptor(warning, store);

    // TODO: Add Restricted guard
    router.beforeEach((to, from, next) => {
        warning.commit('log', {
            has: false,
            message: '',
            action: '',
        });
        next();
    });

    return { app, router, graphQLProvider };
}

export function createGraphQLProvider(initialState) {
    const client =
        initialState && initialState.APOLLO_STATE
            ? GraphQLClientFactory(config.graphQLEndpoint, false, initialState.APOLLO_STATE)
            : GraphQLClientFactory(config.graphQLEndpoint, true);

    return client;
}
