<template>
    <div id="approot">
        <context-view :visible="contextViewVisible" />
        <!-- 'router-view' is a "global" component that is injected into the Vue component registry by vue-router. -->
        <router-view />
        <div v-if="languageIsChanging" class="alert alert-info">Language is changing...</div>
    </div>
</template>

<script>
// TODO: Remove unused
import ContextView from './ContextView';
import { dictionaryServiceFactory } from './lib/dictionary-service-factory';
import { useCookies } from 'vue3-cookies';

export default {
    name: 'AppRoot',
    setup() {
        const { cookies } = useCookies();
        return {
            languageIsChanging: false,
            contextViewVisible: false,
            cookies,
        };
    },
    mounted() {
        let isLoggedIn = false;
        if (this.cookies.isKey('lberhverv.session')) {
            let cookiePayload = new URLSearchParams(decodeURI(this.cookies.get('lberhverv.session')));
            isLoggedIn = cookiePayload.get('loggedin') == 'y';
            this.$store.dispatch('login', {
                isLoggedIn: isLoggedIn,
                name: cookiePayload.get('name'),
            });
        }
        if (!isLoggedIn) {
            const href = window.location.href.toLocaleLowerCase();
            if (
                href.indexOf('/onboarding') > -1 ||
                href.indexOf('/restricted') > -1 ||
                href.indexOf('/restricted/collections') > -1
            ) {
                window.location.assign('/miterhverv');
            }
        }
    },
    methods: {
        changeAppLanguage(language) {
            const i18n = this.$i18n;
            if (i18n.locale !== language) {
                // Changing languages is an async action, therefore the `languageIsChanging` property can be used
                // to show a loading/switching screen when language is being changed.
                this.languageIsChanging = true;

                // create an instance of the dictonary service
                const dictionaryServiceInstance = dictionaryServiceFactory.create();

                dictionaryServiceInstance.fetchDictionaryData(language).then((phrases) => {
                    i18n.setLocaleMessage(language, phrases);
                    i18n.locale = language;

                    this.languageIsChanging = false;
                });
            }
        },
        toggleContextView() {
            this.contextViewVisible = !this.contextViewVisible;
        },
    },
    provide() {
        // Use Vue's provide/inject capabilities to "provide" functions to
        // any descendant component that want to use/"inject" the functions.
        return {
            languageIsChanging: this.languageIsChanging,
            changeAppLanguage: this.changeAppLanguage,
            toggleContextView: this.toggleContextView,
        };
    },
    components: {
        ContextView,
    },
};
</script>
