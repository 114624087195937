import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-34a7baf2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-80 min-h-min bg-white p-8 rounded-2xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Overlay = _resolveComponent("Overlay")!

  return (_openBlock(), _createBlock(_Transition, null, {
    default: _withCtx(() => [
      (_ctx.show)
        ? (_openBlock(), _createBlock(_component_Overlay, { key: 0 }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("a", {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => {
                        _ctx.visible = false;
                        _ctx.$emit('close');
                    }),
                  class: "block text-right cursor-pointer font-bold text-disturber text-lg leading-4 mb-4"
                }, "X"),
                _renderSlot(_ctx.$slots, "default", {}, undefined, true)
              ])
            ]),
            _: 3
          }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}