
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { defineComponent } from 'vue';

export default defineComponent({
    components: {
        ScText: Text,
    },
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
    },
    setup() {},
});
