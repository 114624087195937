
import { RichText, Text } from '@sitecore-jss/sitecore-jss-vue';
import { defineComponent, PropType } from 'vue';
import { Hero } from './Hero.types';

export default defineComponent({
    name: 'Hero',
    components: {
        ScRichText: RichText,
        ScText: Text,
    },
    props: {
        fields: {
            type: Object as PropType<Hero>,
            default: () => ({
                heading: {
                    value: '',
                },
                content: {
                    value: '',
                },
                backgroundImage: {
                    value: {
                        src: '',
                    },
                },
            }),
        },
    },
    computed: {
        backgroundImageUrl(): string {
            return this.fields.backgroundImage.value.src;
        },
    },
});
