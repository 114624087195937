
import { defineComponent } from 'vue';
import IBSuiteService from '../services/ibsuite.service';

export default defineComponent({
    name: 'ExistingRegistration',
    components: {},
    data() {
        const cvr = this.$store.state.cvr;

        return {
            cvr: cvr,
            registrationDeleted: false,
            markForDeletion: false,
        };
    },
    methods: {
        async deleteRegistration() {
            return IBSuiteService.deleteMember().then(() => {
                this.$store.state.cvr = '';
                this.$store.state.loggedIn = false;
                this.registrationDeleted = true;
                this.markForDeletion = false;
            });
        },
        async gotoRestricted() {
            await IBSuiteService.exchangeOnboardingToken().then(() => {
                this.$store.dispatch('login', {
                    isLoggedIn: true,
                });
                this.$router.push('/restricted');
            });
        },
        gotoOnboarding() {
            this.$router.push('/onboarding');
        },
    },
});
