import IBSuiteService from '../services/ibsuite.service';

export default {
    methods: {
        prettyfyValidFromDate(date) {
            if (!date || date === null || date.toLocaleLowerCase() === 'null') {
                return '';
            }

            var today = new Date();
            var validFrom = new Date(date);

            if (today < validFrom) {
                return validFrom.toLocaleDateString('da-DK', { year: 'numeric', month: 'long', day: 'numeric' });
            }
            return '';
        },
        download(pdfElement) {
            IBSuiteService.fetchPdfContent(pdfElement.href, pdfElement.filename);
        },
    },
};
