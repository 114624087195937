import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sc_link = _resolveComponent("sc-link")!

  return (_openBlock(), _createBlock(_component_sc_link, {
    field: _ctx.link,
    class: _normalizeClass([
            'button',
            {
                'button-fixed': _ctx.isFixed,
                'button-minimal': _ctx.isMinimal,
                'button-outline': _ctx.isOutlined,
                'button-small': _ctx.isSmall,
                'button-text': _ctx.isText,
                'button-icon': _ctx.icon,
                'button-secondary': _ctx.secondary,
            },
        ])
  }, null, 8, ["field", "class"]))
}