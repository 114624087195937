
import { defineComponent } from 'vue';
import Overlay from './Overlay.vue';

export default defineComponent({
    name: 'Modal',
    components: { Overlay },
    props: {
        show: {
            type: Boolean,
            default: () => false,
        },
    },
    setup() {},
});
