<template>
    <section class="ribbon" :id="fields.anchorName.value">
        <div class="ribbon-content grid md:grid-cols-3 gap-1">
            <router-link
                v-for="item in fields.items"
                :key="item.id"
                :to="item.url"
                class="grid bg-disturber text-white h-48 md:h-64 p-8"
            >
                <sc-text :field="item.fields.pageTitle" tag="h2" class="text-white" />
                <sc-text :field="item.fields.abstract" class="text-white" />
            </router-link>
        </div>
    </section>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Box',
    components: {
        ScText: Text,
    },
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
    },
    setup() {
        return;
    },
});
</script>
