
import { defineComponent, ToastMessage } from 'vue';

export default defineComponent({
    data() {
        return {
            show: false,
        };
    },
    computed: {
        toastMessage(): ToastMessage {
            return this.$store.state.toastMessage;
        },
    },
    watch: {
        toastMessage() {
            this.show = this.toastMessage.label !== null;

            setTimeout(() => {
                this.$store.dispatch('toastMessage', {
                    label: null,
                    type: null,
                });
            }, 5000);
        },
    },
});
