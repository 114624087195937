import axios from 'axios';

export function httpExceptionInterceptor(warning, store) {
    axios.interceptors.response.use(
        function (response) {
            warning.commit('log', {
                has: false,
                message: '',
                action: '',
            });
            return response;
        },
        function (error) {
            error.stack = '';

            if (error.loginExpired) {
                store.commit('logout', { target: '/miterhverv' });
                return Promise.reject(error);
            }

            const status = error.message.indexOf('404') === -1;
            if (status) {
                warning.commit('log', {
                    has: true,
                    message: error.message,
                    action: 'Login',
                });
            }

            return Promise.reject(error);
        }
    );
}
