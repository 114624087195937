import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sc_link = _resolveComponent("sc-link")!

  return (_openBlock(), _createBlock(_component_sc_link, {
    field: _ctx.link,
    disabled: "disabled",
    class: "inline-block bg-disturber hover:bg-primary px-6 py-3 text-white rounded-full mt-8 after:content-['g'] after:font-icon after:inline-block after:rotate-[270deg] after:ml-6 after:text-xs"
  }, null, 8, ["field"]))
}