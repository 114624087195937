
// TODO: Figure out how to exclude this in build and deployment for the higher environments

import { defineComponent } from 'vue';
import jwt_decode, { JwtPayload } from 'jwt-decode';

export default defineComponent({
    name: 'DebugBar',
    components: {},
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        const debug = {
            showDebugBar: false,
            jwt: '',
            saved: false,
            expiration: '',
            updated: false,
            pid: '',
            rid: '',
            serial: '',
        };
        return {
            debug: debug,
        };
    },
    methods: {
        login() {
            window.location.href = '/Login?jwt=' + this.debug.jwt;
        },
        handleFocus(e) {
            e.target.select();
        },
        toggleDebugbar() {
            this.debug.showDebugBar = !this.debug.showDebugBar;
            this.debug.jwt = window.localStorage.getItem('jwt') || '';
            const decodedJwt = this.debug.jwt ? jwt_decode<JwtPayload>(this.debug.jwt) : {};
            this.debug.expiration = decodedJwt.exp ? new Date(decodedJwt.exp * 1000).toLocaleString() : '';
            this.debug.pid = decodedJwt['PID'] || '';
            this.debug.rid = decodedJwt['RID'] || '';
            this.debug.serial = decodedJwt['SerialNumber'];
        },
    },
});
