
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { defineComponent, ref } from 'vue';
import IBSuiteService from '../services/ibsuite.service';
import ProductIcon from './ProductIcon.vue';
import PoliciesMixin from '../mixins/PoliciesMixin';
import { Policy } from './interfaces/Policy';

export default defineComponent({
    name: 'Policies',
    components: {
        ScText: Text,
        ProductIcon,
    },
    mixins: [PoliciesMixin],
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
    },
    setup() {
        const data = ref<Policy[]>([]);

        return {
            data,
        };
    },
    async created() {
        if (this.$store.state.loggedIn) {
            await IBSuiteService.fetchPolicies().then((res) => {
                this.data = res;
            });
        }
    },
    computed: {
        quoteAccepted() {
            return this.$store.state.quoteAccepted;
        },
    },
    watch: {
        async quoteAccepted() {
            if (this.$store.state.loggedIn) {
                await IBSuiteService.fetchPolicies().then((res) => {
                    this.data = res;
                    this.$store.dispatch('quoteAccepted', false);
                });
            }
        },
    },
});
