import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, null, {
    default: _withCtx(() => [
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["fixed left-4 sm:left-auto right-4 w-full-4 bottom-4 sm:w-96 p-4 shadow-lg", _ctx.toastMessage.type])
          }, [
            _createElementVNode("a", {
              class: "absolute right-4 cursor-pointer font-bold text-slate-500 text-lg leading-4 mb-4",
              onClick: _cache[0] || (_cache[0] = ($event: any) => {
                    _ctx.show = false;
                    _ctx.$store.dispatch('toastMessage', {
                        label: null,
                        type: null,
                    });
                })
            }, "X"),
            _createTextVNode(" " + _toDisplayString(_ctx.toastMessage.label), 1)
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}