
import { Placeholder, Text } from '@sitecore-jss/sitecore-jss-vue';
import { defineComponent, PropType } from 'vue';
import { Navigation } from './Navigation.types';

const baseUrl = process.env.NODE_ENV === 'development' ? '' : '/dist/lbf-ekko';
export default defineComponent({
    name: 'Navigation',
    components: {
        // ScImage: Image,
        ScPlaceholder: Placeholder,
        ScText: Text,
    },
    props: {
        fields: {
            type: Object as PropType<Navigation>,
            default: () => ({
                links: [
                    {
                        id: '',
                        url: '',
                        name: '',
                        displayName: '',
                        fields: {
                            abstract: {
                                value: '',
                            },
                            pageTitle: {
                                value: '',
                            },
                        },
                    },
                ],
            }),
        },
    },
    data() {
        return {
            navOpen: false,
            scrolledFromTop: false,
            logo: baseUrl + '/img/logo_name.svg',
            wave_logo: baseUrl + '/img/logo_wave.svg',
            mobile_logo: baseUrl + '/img/logo_mobile.svg',
        };
    },
    computed: {
        parentRendering() {
            return this.$parent.rendering;
        },
    },
    async mounted() {
        this.handleScroll();
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleScroll);
    },
    methods: {
        handleScroll() {
            if (typeof window !== 'undefined') {
                window.pageYOffset >= 50 ? (this.scrolledFromTop = true) : (this.scrolledFromTop = false);
            }
        },
        login() {
            return this.$router.push('/miterhverv');
        },
        logout() {
            this.$store.dispatch('logout', { target: '/miterhverv' });
        },
    },
});
