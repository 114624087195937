
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { defineComponent, ref } from 'vue';
import IBSuiteService from '../services/ibsuite.service';
import { Collection } from '@/components/interfaces/Collections';

export default defineComponent({
    components: {
        ScText: Text,
    },
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
    },
    setup() {
        const data = ref<Collection[]>([]);
        return { data };
    },
    methods: {
        download(href, name): void {
            IBSuiteService.fetchPdfContent(href, name);
        },
    },
    async created() {
        if (this.$store.state.loggedIn) {
            await IBSuiteService.fetchCollections().then((res) => {
                this.data = res;
            });
        }
    },
});
