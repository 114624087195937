<template>
    <section class="ribbon" :id="fields.anchorName.value">
        <div class="ribbon-content grid md:grid-cols-2 divide-y md:divide-y-0 md:divide-x gap-8">
            <div v-if="fields.left" class="md:pr-8">
                <sc-text tag="h2" :field="fields.left.fields.title" />
                <sc-rich-text :field="fields.left.fields.content" />
                <link-button v-if="fields.left.fields.button" :link="fields.left.fields.button.fields.link" />
            </div>
            <div v-if="fields.right" class="pt-8 md:pt-0 md:pl-8">
                <sc-text tag="h2" :field="fields.right.fields.title" />
                <sc-rich-text :field="fields.right.fields.content" />
                <link-button v-if="fields.right.fields.button" :link="fields.right.fields.button.fields.link" />
            </div>
        </div>
    </section>
</template>

<script>
import { RichText, Text } from '@sitecore-jss/sitecore-jss-vue';
import { defineComponent } from 'vue';
import LinkButton from './Buttons/LinkButton.vue';

export default defineComponent({
    name: 'SplitModuleText',
    components: {
        LinkButton,
        ScText: Text,
        ScRichText: RichText,
    },
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
    },
    setup() {
        return;
    },
});
</script>
