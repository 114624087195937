
import { defineComponent } from 'vue';
import IBSuiteService from '../services/ibsuite.service';
import { useCookies } from 'vue3-cookies';

export default defineComponent({
    name: 'Login',
    components: {},
    setup() {
        const { cookies } = useCookies();
        return { cookies };
    },
    async mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        const missingPermissionsForUserErrorCode = '107';
        let errorCode = urlParams.get('errorCode') || '';
        if (errorCode === missingPermissionsForUserErrorCode) {
            this.$router.push('/missingpermission');
            return;
        }

        if (!this.cookies.isKey('lberhverv.session')) {
            this.$router.push('/miterhverv');
        }

        let cookiePayload = new URLSearchParams(decodeURI(this.cookies.get('lberhverv.session')));

        let hasCvr = cookiePayload.get('cvr');
        let name = cookiePayload.get('name') || '';
        if (hasCvr) {
            await IBSuiteService.login().then((res) => {
                if (res) {
                    this.$store
                        .dispatch('login', {
                            isLoggedIn: true,
                            name: name,
                        })
                        .then(() => this.$router.push('/restricted'));
                } else {
                    this.$store.dispatch('logout', { target: '/missingpermission' });
                }
            });
            return;
        }

        this.$store.state.name = name;
        await IBSuiteService.getCvr().then((res) => {
            if (res == null) {
                return this.$router.push('/onboarding');
            } else {
                let cvr = res ? res : '';
                this.$store.state.cvr = cvr;
                this.$router.push('/onboarding/existingregistration');
            }
        });
    },
});
