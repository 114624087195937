<template>
    <section class="ribbon" :id="fields.anchorName.value">
        <div class="ribbon-content">
            <sc-text :field="fields.title" tag="h2" />

            <div class="grid md:grid-cols-2 md:gap-8">
                <div class="divide-y">
                    <accordion-item v-for="(item, index) in items[0]" :key="item.id" :item="items[0][index]" />
                </div>
                <div class="divide-y">
                    <accordion-item v-for="(item, index) in items[1]" :key="item.id" :item="items[1][index]" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { defineComponent } from 'vue';
import AccordionItem from './AccordionItem.vue';

export default defineComponent({
    name: 'Accordion',
    components: {
        AccordionItem,
        ScText: Text,
    },
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        items() {
            const items = [];
            const half = Math.ceil(this.fields.items.length / 2);

            items[0] = this.fields.items.slice(0, half);
            items[1] = this.fields.items.slice(half);

            return items;
        },
    },
});
</script>
