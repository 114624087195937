<template>
    <metainfo>
        <template v-slot:title="{ content }">{{ content }}</template>
    </metainfo>
    <!-- <visitor-identification /> -->
    <placeholder name="jss-header" :rendering="route" />
    <placeholder name="jss-main" :rendering="route" />
    <placeholder name="jss-footer" :rendering="route" />
    <Toast />
    <Loader />
</template>

<script>
import { defineComponent } from 'vue';
import { Placeholder } from '@sitecore-jss/sitecore-jss-vue';
// import Navigation from './components/Navigation.vue';
// import VisitorIdentification from './VisitorIdentification';
import { useMeta } from 'vue-meta';
import { useStore } from 'vuex';
import './assets/app.css';
import Toast from '../src/components/Toast.vue';
import Loader from '../src/components/Loader.vue';

export default defineComponent({
    name: 'Layout',
    props: {
        route: {
            type: Object,
            default: () => ({}),
        },
        // rendering: {
        //     type: Object,
        //     default: () => ({}),
        // },
    },
    setup() {
        useMeta({
            title: 'LB Erhverv',
        });
        // title: (props.route.fields && props.route.fields.pageTitle && props.route.fields.pageTitle.value) || 'Page',
    },
    mounted() {
        const store = useStore();
        store.dispatch('mapRoute', this.route);
    },
    components: {
        Loader,
        Placeholder,
        Toast,
        // Navigation,
        // VisitorIdentification
    },
});
</script>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'FFMetaSerifWebBold';
        src: url('/dist/lbf-ekko/fonts/32AC0A_3_0.woff2') format('woff2'),
            url('/dist/lbf-ekko/fonts/32AC0A_3_0.woff') format('woff');
    }

    @font-face {
        font-family: 'MetaSerif';
        src: url('/dist/lbf-ekko/fonts/32AC0A_1_0.woff2') format('woff2'),
            url('/dist/lbf-ekko/fonts/32AC0A_1_0.woff') format('woff');
    }

    @font-face {
        font-family: 'MetaPro-Normal';
        src: url('/dist/lbf-ekko/fonts/MetaPro-Normal.woff2') format('woff2'),
            url('/dist/lbf-ekko/fonts/MetaPro-Normal.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'MetaPro-Book';
        src: url('/dist/lbf-ekko/fonts/MetaPro-Book.woff2') format('woff2'),
            url('/dist/lbf-ekko/fonts/MetaPro-Book.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'MetaPro-Medium';
        src: url('/dist/lbf-ekko/fonts/MetaPro-Medium.woff2') format('woff2'),
            url('/dist/lbf-ekko/fonts/MetaPro-Medium.woff') format('woff');
        font-weight: 500;
        font-style: normal;
    }

    @font-face {
        font-family: 'icon-font';
        src: url('/dist/lbf-ekko/fonts/LBF-font.ttf?zdmxnu') format('truetype'),
            url('/dist/lbf-ekko/fonts/LBF-font.eot?zdmxnu#iefix') format('embedded-opentype'),
            url('/dist/lbf-ekko/fonts/LBF-font.woff?zdmxnu') format('woff'),
            url('/dist/lbf-ekko/fonts/LBF-font.svg?zdmxnu#LBF-font') format('svg');
        font-weight: normal;
        font-style: normal;
    }

    body {
        @apply pt-16;
        @apply md:pt-32;
        @apply text-base;
        @apply text-gray-500;
    }

    .ribbon {
        @apply py-12;
        @apply md:py-24;

        &:nth-child(even) {
            @apply bg-gray-100;
        }
    }

    .ribbon-content {
        @apply container;
        @apply mx-auto;
        @apply px-8;

        ul:not(.download) {
            @apply my-4;

            @apply list-none;

            li {
                @apply before:content-['•'];
                @apply before:text-primary;
                @apply before:mr-2;
            }
        }
    }

    .dark {
        @apply bg-primary;

        ul:not(.download) {
            li {
                @apply before:text-white;
            }
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        @apply mb-4;
        @apply text-primary;
        @apply font-serif;
    }

    h1 {
        @apply text-4xl;
    }

    h2 {
        @apply text-3xl;
    }

    h3 {
        @apply text-lg;
    }

    h4 {
        @apply text-base;
    }

    p {
        @apply my-4;
    }

    a {
        @apply text-primary;

        &:disabled {
            @apply bg-red-500;
        }
    }

    .button {
        @apply inline-block;
        @apply bg-primary;
        @apply hover:bg-primary;
        @apply px-6;
        @apply py-2;
        @apply text-white;
        @apply rounded-full;
        @apply mt-8;
        @apply border-2;
        @apply border-primary;
        @apply hover:bg-secondary;
        @apply hover:border-secondary;
        @apply disabled:bg-gray-200;
        @apply disabled:text-gray-400;
        @apply disabled:border-gray-200;
        @apply disabled:cursor-not-allowed;
        @apply disabled:after:hover:translate-x-0;
        @apply font-boldSans;

        &.button-fixed {
            @apply w-60;
        }

        &.button-small {
            @apply py-1;
        }

        &.button-outline {
            @apply bg-white;
            @apply text-disturber;
            @apply border-gray-300;
            @apply hover:bg-gray-100;
        }

        &.button-text {
            @apply bg-transparent;
            @apply text-disturber;
            @apply border-white;
        }

        &.button-minimal {
            @apply px-0;
            @apply after:ml-0;
            width: 43px;
        }

        &.button-icon {
            @apply after:content-['g'];
            @apply after:font-icon;
            @apply after:inline-block;
            @apply after:rotate-[270deg];
            @apply after:ml-4;
            @apply after:text-xs;
            @apply after:transition-transform;
            @apply after:hover:duration-200;
            @apply after:hover:translate-x-1;
        }

        &.button-secondary {
            @apply bg-disturber;
            @apply border-disturber;
            @apply hover:bg-secondary;
            @apply hover:border-secondary;
        }
    }

    .icon,
    [class^='icon-'],
    [class*=' icon-'] {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'icon-font', sans-serif !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        display: block;
        transition: color 0.2s ease-out;
    }

    .icon-gaffeltruck:before {
        @apply content-['\e917'];
    }

    .icon-trailer:before {
        @apply content-['\e918'];
    }

    .icon-enterprise-og-montage:before {
        @apply content-['\e916'];
    }

    .icon-erhvervs-produktansvar:before {
        @apply content-['\e913'];
    }

    .icon-loesoere:before {
        @apply content-['\e914'];
    }

    .icon-noegleperson:before {
        @apply content-['\e915'];
    }

    .icon-mobility:before {
        @apply content-['\e908'];
        color: #4a4a4a;
    }

    .icon-arrow-down:before {
        @apply content-['\67'];
        @apply rotate-90;
    }

    .icon-profile:before {
        @apply content-['\e907'];
    }

    .icon-other-vehicles:before {
        @apply content-['\e901'];
    }

    .icon-rocket:before {
        @apply content-['\e902'];
    }

    .icon-question:before {
        @apply content-['\e903'];
    }

    .icon-disk:before {
        @apply content-['\e904'];
    }

    .icon-check:before {
        @apply content-['\e905'];
    }

    .icon-arrow-down-circle:before {
        @apply content-['\3a'];
    }

    .icon-error:before {
        @apply content-['\3b'];
    }

    .icon-attachment:before {
        @apply content-['\3c'];
    }

    .icon-basket:before {
        @apply content-['\3d'];
    }

    .icon-box:before {
        @apply content-['\3e'];
    }

    .icon-check-mark:before {
        @apply content-['\3f'];
    }

    .icon-download:before {
        @apply content-['\40'];
    }

    .icon-edit:before {
        @apply content-['\41'];
    }

    .icon-inbox:before {
        @apply content-['\42'];
    }

    .icon-info:before {
        @apply content-['\30'];
    }

    .icon-menu:before {
        @apply content-['\61'];
    }

    .icon-calender:before {
        @apply content-['\62'];
    }

    .icon-letter:before {
        @apply content-['\64'];
    }

    .icon-check-box-checked:before {
        @apply content-['\6b'];
    }

    .icon-e-mail:before {
        @apply content-['\6d'];
    }

    .icon-open-close:before {
        @apply content-['\6e'];
    }

    .icon-search:before {
        @apply content-['\76'];
    }

    .icon-arbejdsskade:before {
        @apply content-['\e90a'];
    }

    .icon-bestyrelsesansvar:before {
        @apply content-['\e90b'];
    }

    .icon-bygning:before {
        @apply content-['\e90c'];
    }

    .icon-cyber:before {
        @apply content-['\e90d'];
    }

    .icon-kunst:before {
        @apply content-['\e90e'];
    }

    .icon-maskinkasko:before {
        @apply content-['\e90f'];
    }

    .icon-netbank:before {
        @apply content-['\e910'];
    }

    .icon-prof-ansvar:before {
        @apply content-['\e911'];
    }

    .icon-retshjaelp:before {
        @apply content-['\e912'];
    }

    .icon-car-electric:before {
        @apply content-['\e909'];
    }

    .icon-veteran:before {
        @apply content-['\6a'];
    }

    .icon-child-accident:before {
        @apply content-['\68'];
    }

    .icon-content:before {
        @apply content-['\31'];
    }

    .icon-accident:before {
        @apply content-['\32'];
    }

    .icon-travel:before {
        @apply content-['\33'];
    }

    .icon-house:before {
        @apply content-['\34'];
    }

    .icon-car:before {
        @apply content-['\35'];
    }

    .icon-summer-house:before {
        @apply content-['\36'];
    }

    .icon-health:before {
        @apply content-['\37'];
    }

    .icon-study:before {
        @apply content-['\38'];
    }

    .icon-pet:before {
        @apply content-['\39'];
    }

    .icon-motorcycle:before {
        @apply content-['\71'];
    }

    .icon-motoped:before {
        @apply content-['\77'];
    }

    .icon-camping-van:before {
        @apply content-['\65'];
    }

    .icon-boat:before {
        @apply content-['\72'];
    }

    .icon-life-and-pension:before {
        @apply content-['\74'];
    }

    .icon-key:before {
        @apply content-['\79'];
    }

    .icon-company:before {
        @apply content-['\75'];
    }

    .icon-salary:before {
        @apply content-['\69'];
    }

    .icon-arrow-right-long:before {
        @apply content-['\6f'];
    }

    .icon-arrow-right-short:before {
        @apply content-['\70'];
    }

    .icon-circle:before {
        @apply content-['\63'];
    }

    .icon-arrow-right-normal:before {
        @apply content-['\73'];
    }

    .icon-circle-check:before {
        @apply content-['\66'];
    }

    .icon-trash:before {
        @apply content-['\e906'];
    }

    .icon-construction:before {
        @apply content-['\e900'];
    }

    .icon-facebook:before {
        @apply content-['\ea91'];
    }

    .icon-linkedin:before {
        @apply content-['\eac9'];
    }

    .icon-close:before,
    .icon-cross:before {
        @apply content-['\6e'];
        @apply inline-block;
        @apply rotate-45;
    }

    .v-enter-active,
    .v-leave-active {
        @apply transition-opacity;
        @apply duration-200;
        @apply ease-in-out;
    }

    .v-enter-from,
    .v-leave-to {
        @apply opacity-0;
    }
}

// .component {
//     width: 100%;
//     max-width: 1200px;
//     padding: 0 3.75rem;
//     margin: 6rem 0;

//     @media only screen and (max-width: 768px) {
//         padding: 0 1rem;
//     }
// }

// .cell {
//     display: flex;
//     flex: 0 0 auto;
//     min-height: 0px;
//     min-width: 0px;
//     width: 100%;

//     @media only screen and (max-width: 768px) {
//         width: auto;
//     }
// }

// .button {
//     position: relative;
//     display: inline-flex;
//     width: 100%;
//     height: 40px;
//     padding: 0 1rem !important;
//     margin: 0 0 1rem 0;
//     -webkit-appearance: none;
//     font-family: inherit;
//     font-size: 0.9333333333rem;
//     line-height: 0.9333333333rem;
//     color: #fefefe;
//     text-align: left;
//     text-decoration: none;
//     cursor: pointer;
//     background-color: #3a586d;
//     border: 0 solid transparent;
//     border-radius: 1.3333333333rem;
//     outline: none;
//     align-items: center;

//     &.white:hover,
//     &.white:focus {
//         color: #4a4a4a !important;
//         background-color: #fefefe;
//     }

//     &.white {
//         color: #4a4a4a !important;
//         background-color: #fefefe;
//     }

//     &.secondary {
//         transition: background-color 0.25s ease-out;
//         background-color: #5691b4;
//         color: #fefefe;

//         &.small {
//             height: 2rem;
//             line-height: 2rem;
//             width: auto;
//         }

//         &:hover,
//         &:focus {
//             background-color: #6b9fbd;
//             transition: background-color 0.25s ease-out;
//         }
//     }
// }
</style>
