import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "ribbon-content grid sm:grid-cols-1 md:grid-cols-1" }
const _hoisted_3 = {
  class: "h-100 md:w-2/5",
  id: "tempid"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sc_image = _resolveComponent("sc-image")!
  const _component_sc_text = _resolveComponent("sc-text")!
  const _component_sc_rich_text = _resolveComponent("sc-rich-text")!
  const _component_link_button = _resolveComponent("link-button")!

  return (_openBlock(), _createElementBlock("section", {
    class: "ribbon",
    id: _ctx.fields.anchorName.value
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        "data-test-flip-wrapper": "",
        class: _normalizeClass(["flex", { 'flex-col-reverse md:flex-row-reverse': _ctx.flip, 'flex-col md:flex-row': !_ctx.flip }])
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_sc_image, {
            media: _ctx.fields.image,
            class: "shadow-lg",
            width: "",
            height: ""
          }, null, 8, ["media"])
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["md:w-3/5 md:pt-0", { 'md:pl-8': !_ctx.flip, 'md:pr-8': _ctx.flip, 'pt-8': !_ctx.flip, 'pb-8': _ctx.flip }])
        }, [
          _createVNode(_component_sc_text, {
            tag: "h2",
            field: _ctx.fields.title,
            id: "heading"
          }, null, 8, ["field"]),
          _createVNode(_component_sc_rich_text, {
            field: _ctx.fields.content,
            id: "content"
          }, null, 8, ["field"]),
          (_ctx.fields.button)
            ? (_openBlock(), _createBlock(_component_link_button, {
                key: 0,
                link: _ctx.fields.button.fields.link
              }, null, 8, ["link"]))
            : _createCommentVNode("", true)
        ], 2)
      ], 2)
    ])
  ], 8, _hoisted_1))
}