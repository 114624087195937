
import { RichText, Text } from '@sitecore-jss/sitecore-jss-vue';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Welcome',
    components: {
        ScText: Text,
        ScRichText: RichText,
    },
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
    },
});
