
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { defineComponent, ref, toRefs } from 'vue';
import PoliciesMixin from '../mixins/PoliciesMixin';
import IBSuiteService from '../services/ibsuite.service';
import Modal from './Modal.vue';
import ProductIcon from './ProductIcon.vue';
import { Quote } from './interfaces/Quote';
import VueButton from './open/Buttons/VueButton.vue';

export default defineComponent({
    name: 'MigrationQuotes',
    components: {
        ScText: Text,
        Modal,
        ProductIcon,
        VueButton,
    },
    mixins: [PoliciesMixin],
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
    },
    setup() {
        const data = ref<Quote[]>([]);
        let showModal = ref(false);
        let selectedAcceptQuote = toRefs({
            title: '',
            url: '',
        });

        return {
            data,
            showModal,
            selectedAcceptQuote,
        };
    },
    created() {
        this.fetchQuotes();
    },

    methods: {
        async fetchQuotes() {
            if (this.$store.state.loggedIn) {
                await IBSuiteService.fetchMigrationQuotes().then((res) => {
                    this.data = res;
                });
            }
        },
        acceptQuote(url, name) {
            this.showModal = true;
            this.selectedAcceptQuote.url.value = url;
            this.selectedAcceptQuote.title.value = 'Accepter tilbud - ' + name;
        },
        async confirmAccept() {
            this.showModal = false;
            this.$store.dispatch('loading', true);
            await IBSuiteService.acceptQuote(this.selectedAcceptQuote.url.value)
                .then(() => {
                    this.$store.dispatch('quoteAccepted', true);
                    this.$store.dispatch('loading', false);
                    this.$store.dispatch('toastMessage', {
                        label: 'Tilbud er accepteret.',
                        type: 'success',
                    });
                    this.fetchQuotes();
                })
                .catch((err) => {
                    console.error('err', err);
                    this.$store.dispatch('loading', false);
                    this.$store.dispatch('toastMessage', {
                        label: 'Tilbud kunne ikke accepteres.',
                        type: 'error',
                    });
                })
                .finally(() => {
                    this.clearSelectedQuote();
                });
        },
        clearSelectedQuote() {
            this.showModal = false;
            this.selectedAcceptQuote.title.value = '';
            this.selectedAcceptQuote.url.value = '';
        },
    },
});
