
import { defineComponent } from 'vue';
import { throttle } from 'lodash';

interface SectionElement {
    id: string;
    offsetTop: number;
    offsetBottom: number;
}

export default defineComponent({
    props: {
        fields: {
            type: Object as any,
            default: () => ({}),
        },
    },
    // data() {
    //     let showRestricted = this.$store.state.loggedIn;
    //     return {
    //         showRestricted: showRestricted,
    //     },
    // },

    computed: {
        sectionElements(): SectionElement[] {
            const arr: SectionElement[] = [];

            document.querySelectorAll('section').forEach((elm) => {
                const id = elm.id;
                const offsetTop = elm.offsetTop;
                const elementHeight = elm.clientHeight;
                const offsetBottom = offsetTop + elementHeight;

                arr.push({
                    id,
                    offsetTop,
                    offsetBottom,
                });
            });

            return arr;
        },
    },
    mounted() {
        this.scroll();
        window.addEventListener(
            'scroll',
            throttle(() => {
                this.scroll();
            }, 200)
        );
    },
    methods: {
        scroll(): void {
            const toggleClassName = 'active';
            const windowScrollTop = window.scrollY + window.innerHeight / 2;

            this.sectionElements.forEach(({ id, offsetTop, offsetBottom }) => {
                const inViewport = windowScrollTop >= offsetTop && windowScrollTop <= offsetBottom;
                const anchorLinkElement = document.querySelector('#router-link-' + id);

                if (anchorLinkElement === null) {
                    return;
                }

                if (inViewport) {
                    return anchorLinkElement.classList.add(toggleClassName);
                }

                return anchorLinkElement.classList.remove(toggleClassName);
            });
        },
    },
});
