
import { Text, RichText } from '@sitecore-jss/sitecore-jss-vue';
import { defineComponent } from 'vue';
import LinkButton from './Buttons/LinkButton.vue';

export default defineComponent({
    name: 'IconList',
    components: {
        LinkButton,
        ScRichText: RichText,
        ScText: Text,
    },
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
    },
    setup() {
        return;
    },
});
