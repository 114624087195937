import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ca3d974"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "scroll-smooth relative z-100 invisible md:visible lg:grid w-full bg-disturber shadow-lg text-white z-50" }
const _hoisted_2 = { class: "container mx-auto px-8" }
const _hoisted_3 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields.sections, (link) => {
          return (_openBlock(), _createElementBlock("li", {
            key: link.fields.anchorName.value,
            class: "inline-block px-4 py-2 hover:bg-secondary",
            id: 'router-link-' + link.fields.anchorName.value
          }, [
            (link.fields.anchorTitle.value)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: '#' + link.fields.anchorName.value,
                  innerHTML: link.fields.anchorTitle.value,
                  class: "text-white",
                  "data-test-anchor-link": ""
                }, null, 8, ["to", "innerHTML"]))
              : (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  to: '#' + link.fields.anchorName.value,
                  innerHTML: link.fields.title.value,
                  class: "text-white",
                  "data-test-anchor-link": ""
                }, null, 8, ["to", "innerHTML"]))
          ], 8, _hoisted_3))
        }), 128))
      ])
    ])
  ]))
}