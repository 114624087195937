<template>
    <section v-if="doRender" class="ribbon" :id="fields.anchorName.value">
        <div class="ribbon-content text-center">
            <sc-text tag="h2" :field="fields.title" />
            <sc-rich-text :field="fields.content" />
            <cta-button v-if="fields.button" :link="fields.button.fields.link" /><br />

            <!-- <div class="grid grid-cols-3">
                <div><vue-button>Default</vue-button></div>
                <div><vue-button size="fixed">Default</vue-button></div>
                <div><vue-button size="small">Default</vue-button></div>
                <div><vue-button type="outline">Outline</vue-button></div>
                <div><vue-button type="outline" size="fixed">Outline</vue-button></div>
                <div><vue-button type="outline" size="small">Outline</vue-button></div>
                <div><vue-button :disabled="true">Disabled</vue-button></div>
                <div><vue-button size="fixed" :disabled="true">Disabled</vue-button></div>
                <div><vue-button size="small" :disabled="true">Disabled</vue-button></div>
                <div><vue-button type="text">Text</vue-button></div>
                <div><vue-button type="text" size="fixed">Text</vue-button></div>
                <div><vue-button type="text" size="small">Text</vue-button></div>
                <div><vue-button size="minimal" /></div>
                <div><vue-button size="minimal" type="outline" /></div>
                <div><vue-button size="minimal" type="text" /></div>

                <div><link-button :link="fields.button.fields.link">Default</link-button></div>
                <div><link-button :link="fields.button.fields.link" size="fixed">Default</link-button></div>
                <div><link-button :link="fields.button.fields.link" size="small">Default</link-button></div>
                <div><link-button :link="fields.button.fields.link" type="outline">Outline</link-button></div>
                <div>
                    <link-button :link="fields.button.fields.link" type="outline" size="fixed">Outline</link-button>
                </div>
                <div>
                    <link-button :link="fields.button.fields.link" type="outline" size="small">Outline</link-button>
                </div>
                <div><link-button :link="fields.button.fields.link" type="text">Text</link-button></div>
                <div><link-button :link="fields.button.fields.link" type="text" size="fixed">Text</link-button></div>
                <div><link-button :link="fields.button.fields.link" type="text" size="small">Text</link-button></div>
                <div><link-button :link="fields.button.fields.link" size="minimal" /></div>
                <div><link-button :link="fields.button.fields.link" size="minimal" type="outline" /></div>
                <div><link-button :link="fields.button.fields.link" size="minimal" type="text" /></div>
            </div> -->
        </div>
    </section>
</template>

<script>
import { RichText, Text } from '@sitecore-jss/sitecore-jss-vue';
import { defineComponent } from 'vue';
import CtaButton from './CtaButton.vue';
// import VueButton from './Buttons/VueButton.vue';
// import LinkButton from './Buttons/LinkButton.vue';

export default defineComponent({
    components: {
        CtaButton,
        ScText: Text,
        ScRichText: RichText,
        // VueButton,
        // LinkButton,
    },
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        doRender() {
            return Object.keys(this.fields).length !== 0;
        },
    },
});
</script>
