import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-gray-100 bg-opacity-50" }
const _hoisted_2 = { class: "container mx-auto grid py-8 px-8" }
const _hoisted_3 = { class: "grid md:grid-cols-3 lg:grid-cols-6 gap-8" }
const _hoisted_4 = { class: "grid col-span-2 grid-cols-2 lg:col-span-5 lg:grid-cols-5 gap-8" }
const _hoisted_5 = ["href", "innerHTML"]
const _hoisted_6 = ["href", "innerHTML"]
const _hoisted_7 = { class: "pb-8" }
const _hoisted_8 = { class: "grid md:grid-cols-6 gap-8" }
const _hoisted_9 = { class: "grid md:col-span-4 lg:col-span-5" }
const _hoisted_10 = { class: "grid lg:grid-cols-5 gap-8" }
const _hoisted_11 = { class: "lg:col-span-4" }
const _hoisted_12 = { class: "grid grid-cols-4 gap-8" }
const _hoisted_13 = { class: "lg:col-span-1" }
const _hoisted_14 = { class: "md:col-span-2 lg:col-span-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sc_text = _resolveComponent("sc-text")!
  const _component_sc_rich_text = _resolveComponent("sc-rich-text")!
  const _component_sc_link = _resolveComponent("sc-link")!
  const _component_sc_image = _resolveComponent("sc-image")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createVNode(_component_sc_text, {
              tag: "h4",
              field: _ctx.fields.heading
            }, null, 8, ["field"]),
            _createVNode(_component_sc_rich_text, {
              field: _ctx.fields.address,
              class: "mt-0"
            }, null, 8, ["field"]),
            _createElementVNode("p", null, _toDisplayString(_ctx.fields.cvrLabel.value) + " " + _toDisplayString(_ctx.fields.cvr.value), 1),
            _createElementVNode("p", null, [
              _createTextVNode(_toDisplayString(_ctx.fields.phoneLabel.value) + " ", 1),
              _createElementVNode("a", {
                href: `tel:${_ctx.fields.phone.value}`,
                innerHTML: _ctx.fields.phone.value
              }, null, 8, _hoisted_5)
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields.linkGroups, (linkGroup) => {
            return (_openBlock(), _createElementBlock("div", {
              key: linkGroup.id,
              class: "pb-8"
            }, [
              _createVNode(_component_sc_text, {
                tag: "h4",
                field: linkGroup.fields.heading
              }, null, 8, ["field"]),
              _createElementVNode("ul", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(linkGroup.fields.links, (link) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: link.id
                  }, [
                    _createElementVNode("a", {
                      href: link.url,
                      innerHTML: link.fields.pageTitle.value,
                      class: "text-black"
                    }, null, 8, _hoisted_6)
                  ]))
                }), 128))
              ])
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_sc_text, {
            tag: "h4",
            field: _ctx.fields.externalLinksHeading
          }, null, 8, ["field"]),
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields.externalLinks, (link) => {
              return (_openBlock(), _createElementBlock("li", {
                key: link.id
              }, [
                _createVNode(_component_sc_link, {
                  field: link.fields.link,
                  class: "text-black"
                }, null, 8, ["field"])
              ]))
            }), 128))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_sc_text, {
                tag: "h4",
                field: _ctx.fields.logoHeaderFirst,
                class: "border-b border-gray-200 pb-2"
              }, null, 8, ["field"]),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_sc_image, {
                  media: _ctx.fields.lbLogo,
                  class: "h-12"
                }, null, 8, ["media"]),
                _createVNode(_component_sc_image, {
                  media: _ctx.fields.bautaLogo,
                  class: "h-12"
                }, null, 8, ["media"]),
                _createVNode(_component_sc_image, {
                  media: _ctx.fields.runaLogo,
                  class: "h-12"
                }, null, 8, ["media"]),
                _createVNode(_component_sc_image, {
                  media: _ctx.fields.lbErhvervLogo,
                  class: "mt-2 mh-12"
                }, null, 8, ["media"])
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createVNode(_component_sc_text, {
                tag: "h4",
                field: _ctx.fields.logoHeaderSecond,
                class: "border-b border-gray-200 pb-2"
              }, null, 8, ["field"]),
              _createVNode(_component_sc_image, {
                media: _ctx.fields.pfaLogo,
                class: "h-12"
              }, null, 8, ["media"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("ul", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields.serviceNavigationLinks, (link) => {
              return (_openBlock(), _createElementBlock("li", {
                key: link.id
              }, [
                _createVNode(_component_sc_link, {
                  field: link.fields.link,
                  class: "text-black"
                }, null, 8, ["field"])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}