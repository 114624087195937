
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ProductIcon',

    props: {
        name: {
            type: String,
            default: () => '',
        },
    },
    setup() {},
    methods: {
        mapProductNameToIcon(productName) {
            let icon = '';
            switch (productName) {
                case 'Arbejdsskadeforsikring': {
                    icon = '&#xe90a;';
                    break;
                }
                case 'Biler og Arbejdsmaskiner': {
                    // TODO: Review value
                    icon = '5';
                    break;
                }
                case 'Bygningsforsikring': {
                    icon = '&#xe90c;';
                    break;
                }
                case 'Bilforsikring': {
                    icon = '5';
                    break;
                }
                case 'Cyber': {
                    icon = '&#xe90d;';
                    break;
                }
                case 'Direktions og bestyrelsesansvar': {
                    icon = '&#xe90b;';
                    break;
                }
                case 'Entrepriseforsikring': {
                    icon = '&#xe916;';
                    break;
                }
                case 'Erhvervs- og produktansvarsforsikring': {
                    icon = '&#xe913;';
                    break;
                }
                case 'Erhvervsrejseforsikring': {
                    icon = '3';
                    break;
                }
                case 'Kunstforsikring': {
                    icon = '&#xe90e;';
                    break;
                }
                case 'Løsøreforsikring': {
                    icon = '&#xe914;';
                    break;
                }
                case 'Maskinkasko og maskindriftstab': {
                    icon = '&#xe90f;';
                    break;
                }
                case 'Netbank': {
                    icon = '&#xe910;';
                    break;
                }
                case 'Nøglepersonforsikring': {
                    icon = '&#xe915;';
                    break;
                }
                case 'Professionel ansvarsforsikring': {
                    icon = '&#xe911;';
                    break;
                }
                case 'Retshjælpsforsikring': {
                    icon = '&#xe912;';
                    break;
                }
                case 'Sundhedsforsikring': {
                    icon = '7;';
                    break;
                }
                case 'Bygningsforsikring SME': {
                    icon = '4';
                    break;
                }
                case 'Bil SME': {
                    icon = '5';
                    break;
                }
                case 'Retshjælp SME': {
                    icon = '&#xe912;';
                    break;
                }
                case 'Trailer SME': {
                    // TODO: Review value
                    icon = '5';
                    break;
                }
                case 'Løsøreforsikring SME': {
                    icon = '&#xe914;';
                    break;
                }
                case 'Erhvervs og produktansvarforsikring SME': {
                    icon = '&#xe913;';
                    break;
                }
                default: {
                    icon = '';
                    break;
                }
            }
            return icon;
        },
    },
});
