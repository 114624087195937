import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ribbon-content grid" }
const _hoisted_2 = { class: "dark p-8 mb-8 mt-8 md:mt-16 lg:mt-32 text-white drop-shadow-lg sm:w-1/2 md:w-1/2 lg:w-1/3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sc_text = _resolveComponent("sc-text")!
  const _component_sc_rich_text = _resolveComponent("sc-rich-text")!

  return (_openBlock(), _createElementBlock("section", {
    class: "bg-cover bg-right-top",
    style: _normalizeStyle('background-image: url(' + _ctx.backgroundImageUrl + ')')
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_sc_text, {
          field: _ctx.fields.heading,
          tag: "h1",
          class: "text-white"
        }, null, 8, ["field"]),
        _createVNode(_component_sc_rich_text, {
          field: _ctx.fields.content
        }, null, 8, ["field"])
      ])
    ])
  ], 4))
}