const POLICIES_URL = '/v1/api/policies';
const PROFILE_URL = '/v1/api/profile';
const MIGRATION_QUOTES_URL = '/v1/api/quotes';
const COLLECTIONS_URL = '/v1/api/collections';

const CVR_MAPPER_RID_URL = '/v1/api/cvrservice/rid';
const CVR_MAPPER_UUID_URL = '/v1/api/cvrservice/uuid';

const MEMBERSHIP_URL = '/v1/api/lbemember';
const LOGIN_URL = '/v1/api/login';
const LOGOUT_URL = '/v1/api/login/logout';
const EXCHANGE_ONBOARDING_TOKEN_URL = '/v1/api/login/onboarding';

export const getMigrationQuotesUrl = () => {
    return process.env.VUE_APP_ROOT_API + MIGRATION_QUOTES_URL;
};

export const getPoliciesUrl = () => {
    return process.env.VUE_APP_ROOT_API + POLICIES_URL;
};

export const getCollectionsUrl = () => {
    return process.env.VUE_APP_ROOT_API + COLLECTIONS_URL;
};

export const getProfileUrl = () => {
    return process.env.VUE_APP_ROOT_API + PROFILE_URL;
};

export const getCVRMapperUrlRid = () => {
    return process.env.VUE_APP_ROOT_API + CVR_MAPPER_RID_URL;
};

export const getCVRMapperUrlUuid = () => {
    return process.env.VUE_APP_ROOT_API + CVR_MAPPER_UUID_URL;
};

export const getMembershipUrl = () => {
    return process.env.VUE_APP_ROOT_API + MEMBERSHIP_URL;
};

export const getExchangeTokenUrl = () => {
    return process.env.VUE_APP_ROOT_API + EXCHANGE_ONBOARDING_TOKEN_URL;
};

export const getLoginUrl = () => {
    return process.env.VUE_APP_ROOT_API + LOGIN_URL;
};

export const getLogoutUrl = () => {
    return process.env.VUE_APP_ROOT_API + LOGOUT_URL;
};

export default {
    getPoliciesUrl,
    getCollectionsUrl,
    getProfileUrl,
    getCVRMapperUrlRid,
    getCVRMapperUrlUuid,
    getMembershipUrl,
    getExchangeTokenUrl,
    getMigrationQuotesUrl,
    getLoginUrl,
    getLogoutUrl,
};
