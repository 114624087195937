
import { Image, Text } from '@sitecore-jss/sitecore-jss-vue';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'LargeImageList',
    components: {
        ScImage: Image,
        ScText: Text,
    },
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
    },
});
