
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'MitID',
    components: {},
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        const missingCpr = 'noCpr';

        let errorCode = urlParams.get('errorCode') || '';
        if (errorCode == missingCpr) {
            this.errorMessage = 'Du har forsøgt at logge på MitID Erhverv med dit private MitId';
            this.hasError = true;
        }
    },
    data() {
        return {
            hasError: false,
            errorMessage: '',
        };
    },
    methods: {
        goToMitIdLogin() {
            window.location.assign('/mitid');
        },
    },
});
