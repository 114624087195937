import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "ribbon-content grid" }
const _hoisted_3 = { class: "grid md:grid-cols-3 gap-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sc_text = _resolveComponent("sc-text")!
  const _component_sc_image = _resolveComponent("sc-image")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("section", {
    class: "ribbon",
    id: _ctx.fields.anchorName.value
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_sc_text, {
        tag: "h2",
        field: _ctx.fields.title,
        id: "heading"
      }, null, 8, ["field"]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields.items, (item) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            to: item.fields.link.value.href,
            key: item.id,
            class: "group"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_sc_image, {
                media: item.fields.image,
                class: "group-hover:shadow-xl group-hover:translate-y-1 transition-transform"
              }, null, 8, ["media"]),
              _createVNode(_component_sc_text, {
                tag: "h3",
                field: item.fields.title,
                class: "mt-4"
              }, null, 8, ["field"]),
              _createVNode(_component_sc_text, {
                tag: "p",
                field: item.fields.content,
                class: "text-black"
              }, null, 8, ["field"])
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ])
    ])
  ], 8, _hoisted_1))
}