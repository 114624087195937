
import { Text } from '@sitecore-jss/sitecore-jss-vue';
import { defineComponent, ref } from 'vue';
import IBSuiteService from '../services/ibsuite.service';
import { Profile } from './interfaces/Profile';

export default defineComponent({
    name: 'Profile',
    components: {
        ScText: Text,
    },
    props: {
        fields: {
            type: Object,
            default: () => ({}),
        },
    },
    setup() {
        const profile = ref<Profile>([]);
        return {
            profile,
        };
    },
    async created() {
        if (this.$store.state.loggedIn) {
            await IBSuiteService.fetchProfile().then((res) => {
                this.profile = res;
            });
        }
    },
});
