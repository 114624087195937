
import { defineComponent, PropType } from 'vue';

type ButtonSize = 'default' | 'fixed' | 'small' | 'minimal';
type ButtonType = 'default' | 'outline' | 'text';

export default defineComponent({
    name: 'VueButton',
    props: {
        disabled: {
            type: Boolean,
            default() {
                return false;
            },
        },
        size: {
            type: String as PropType<ButtonSize>,
            default: () => 'default' as ButtonSize,
        },
        type: {
            type: String as PropType<ButtonType>,
            default: () => 'default' as ButtonType,
        },
        icon: {
            type: Boolean,
            default() {
                return true;
            },
        },
        secondary: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    computed: {
        isFixed(): boolean {
            return this.size === 'fixed';
        },
        isMinimal(): boolean {
            return this.size === 'minimal';
        },
        isOutlined(): boolean {
            return this.type === 'outline';
        },
        isSmall(): boolean {
            return this.size === 'small';
        },
        isText(): boolean {
            return this.type === 'text';
        },
    },
});
