import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([
            'button',
            {
                'button-fixed': _ctx.isFixed,
                'button-minimal': _ctx.isMinimal,
                'button-outline': _ctx.isOutlined,
                'button-small': _ctx.isSmall,
                'button-text': _ctx.isText,
                'button-icon': _ctx.icon,
                'button-secondary': _ctx.secondary,
            },
        ]),
    disabled: _ctx.disabled
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}