
import { RichText, Text, Image } from '@sitecore-jss/sitecore-jss-vue';
import { defineComponent, PropType } from 'vue';
import { SplitModule } from './SplitModule.types';
import LinkButton from '../Buttons/LinkButton.vue';

export default defineComponent({
    name: 'SplitModule',
    components: {
        LinkButton,
        ScText: Text,
        ScRichText: RichText,
        ScImage: Image,
    },
    props: {
        fields: {
            type: Object as PropType<SplitModule>,
            default: () => ({
                anchorName: {
                    value: '',
                },
                image: {
                    value: {
                        src: '',
                    },
                },
                title: {
                    value: '',
                },
                content: {
                    value: '',
                },
                flip: {
                    value: false,
                },
            }),
        },
    },
    setup() {
        return;
    },
    computed: {
        flip() {
            return this.fields.flip.value;
        },
    },
});
