import axios from 'axios';
import saveAs from 'file-saver';
import {
    getCollectionsUrl,
    getCVRMapperUrlRid,
    getCVRMapperUrlUuid,
    getExchangeTokenUrl,
    getMembershipUrl,
    getMigrationQuotesUrl,
    getPoliciesUrl,
    getProfileUrl,
    getLoginUrl,
    getLogoutUrl,
} from '../config/api-endpoints';
import { Collection } from '@/components/interfaces/Collections';
import { Profile } from '@/components/interfaces/Profile';
import { Policy } from '@/components/interfaces/Policy';
import { Quote } from '@/components/interfaces/Quote';

class IBSuiteService {
    async fetchMigrationQuotes(): Promise<Quote[]> {
        return axios
            .get(getMigrationQuotesUrl(), {
                withCredentials: true,
            })
            .then((res) => {
                return res.data;
            })
            .catch((ex) => {
                console.error('fetchMigrationQuotes catch', ex);
            });
    }

    async fetchPolicies(): Promise<Policy[]> {
        return axios
            .get(getPoliciesUrl(), {
                withCredentials: true,
            })
            .then((res) => {
                return res.data;
            })
            .catch((ex) => {
                console.error('fetchPolicies catch', ex);
            });
    }

    async fetchPdfContent(pdfUrl: string, filename: string) {
        return axios
            .get(pdfUrl, {
                withCredentials: true,
                responseType: 'blob',
                timeout: 30000,
            })
            .then((res) => {
                var file = new Blob([res.data], {
                    type: 'application/pdf',
                });
                saveAs(file, filename);
            })
            .catch((ex) => {
                console.error('fetchPdfContent catch', ex);
            });
    }

    async fetchProfile(): Promise<Profile> {
        return axios
            .get(getProfileUrl(), {
                withCredentials: true,
            })
            .then((res) => {
                return res.data;
            })
            .catch((ex) => {
                console.error('fetchPofile catch', ex);
            });
    }

    async fetchCollections(): Promise<Collection[]> {
        return axios
            .get(getCollectionsUrl(), {
                withCredentials: true,
            })
            .then((res) => {
                return res.data as Collection[];
            })
            .catch((ex) => {
                console.error('fectchCollections catch', ex);
                return new Array<Collection>();
            });
    }

    async acceptQuote(acceptUrl: string) {
        return axios.patch(acceptUrl, null, { withCredentials: true });
    }

    async login(): Promise<boolean> {
        return axios
            .get(getLoginUrl(), {
                withCredentials: true,
            })
            .then((res) => {
                if (res && res.data && typeof res.data.success == 'boolean') {
                    return res.data.success;
                }
                return false;
            })
            .catch((ex) => {
                console.error('login catch', ex);
                return false;
            });
    }

    async logout(): Promise<boolean> {
        return axios
            .get(getLogoutUrl(), { withCredentials: true })
            .then(() => {
                return true;
            })
            .catch((ex) => {
                console.error('logout catch', ex);
                return false;
            });
    }

    async cvrMapperRid(cvr: string): Promise<boolean> {
        let cvrLookUp = { cvr: cvr };
        return axios
            .post(getCVRMapperUrlRid(), cvrLookUp, {
                withCredentials: true,
            })
            .then(() => {
                return true;
            })
            .catch((ex) => {
                console.error('cvrMapperRid catch', ex);
                return false;
            });
    }

    async cvrMapperUuid(cpr: string, cvr: string): Promise<boolean> {
        return axios
            .get(getCVRMapperUrlUuid(), {
                withCredentials: true,
                params: {
                    cvr: cvr,
                    cpr: cpr,
                },
            })
            .then(() => {
                return true;
            })
            .catch((ex) => {
                console.error('cvrMapperUuid catch', ex);
                return false;
            });
    }

    async createMember(cpr: string, cvr: string): Promise<boolean> {
        const memberData = { cpr: cpr, cvr: cvr };
        return axios
            .put(getMembershipUrl(), memberData, {
                withCredentials: true,
            })
            .then(() => {
                return true;
            })
            .catch((ex) => {
                console.error('createMember catch', ex);
                return false;
            });
    }

    async getCvr(): Promise<string | null> {
        return axios
            .get(getMembershipUrl(), {
                withCredentials: true,
            })
            .then((res) => {
                return res.data as string;
            })
            .catch((ex) => {
                console.error('getCvr catch', ex);
                return null;
            });
    }

    async deleteMember(): Promise<boolean> {
        return axios
            .delete(getMembershipUrl(), {
                withCredentials: true,
            })
            .then(() => {
                return true;
            })
            .catch((ex) => {
                console.error('deleteMember catch', ex);
                return false;
            });
    }

    async exchangeOnboardingToken(): Promise<boolean> {
        return axios
            .get(getExchangeTokenUrl(), {
                withCredentials: true,
            })
            .then(() => {
                return true;
            })
            .catch((ex) => {
                console.error('exchangeOnboardingToken catch', ex);
                return false;
            });
    }
}

export default new IBSuiteService();
